<template>
  <div v-if="count" class="summary places-summary">
    <h2>Places</h2>

    <SingleNumeric :value="count" /> different places, including
    <span v-for="(place, link, index) in places" :key="index">
      <span v-if="index === lastPlace && count > 1"> and</span>
      a<template v-if="startsWithVowel(place.type)">n</template>&nbsp;<a :href="link">{{ place.type }}</a>
      <template v-if="place.count > 1">&nbsp;(<span class="multiplier">x{{ place.count }}!</span>)</template><span v-if="index < lastPlace - 1">,</span>
    </span>
  </div>
</template>

<style lang="stylus" scoped>
.multiplier
  font-weight bold
</style>

<script>
import SingleNumeric from '@/components/blocks/SingleNumeric.vue'

export default {
  name: 'Places',
  inject: ['getEvents'],
  components: {
    SingleNumeric
  },
  data() {
    return {
      places: {},
      count: 0
    }
  },
  async created() {
    const events = await this.getEvents

    for (const event of events) {
      if (event.feed.short_name !== 'foursquare') {
        continue
      }

      if (event.raw.venue?.categories?.length > 0) {

        if (this.places[event.link]) {
          this.places[event.link].count += 1
        } else {
          this.places[event.link] = {
            count: 1,
            type: event.raw.venue.categories[0].name
          }

          this.count += 1
        }
      }
    }
  },
  computed: {
    lastPlace() {
      return this.count - 1
    }
  },
  methods: {
    startsWithVowel(words) {
      const VOWELS = ['a', 'e', 'i', 'o', 'u']

      return VOWELS.includes(words[0].toLowerCase())
    }
  }
}
</script>