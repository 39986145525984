<template>
  <div class="stream">
    <WeeklyReport
      v-for="(week, index) in weeks" :key="index"
      v-bind="week" />
    <div class="more">
      <a href="#" @click.prevent.stop="loadMore()">MORE!</a>
    </div>
  </div>
</template>

<style lang="stylus">
.more
  padding 20px 0 20px 0
  width 100%
  text-align center
</style>

<script>
import { DateTime } from 'luxon'

import { TIMEZONE } from '@/eventlog'

import WeeklyReport from '@/components/WeeklyReport.vue'

export default {
  name: 'Stream',
  components: {
    WeeklyReport
  },
  props: {
    end: {
      type: Object,
      default: undefined
    },
    initialNumReports: {
      type: Number,
      default: 2
    },
    incrementalNumReports: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      weeks: []
    }
  },
  created() {
    let start = null

    if (this.end !== undefined) {
      start = DateTime.local(this.end.year)
                      .setZone(TIMEZONE)
                      .plus({ weeks: this.end.week - 1 })
    } else {
      start = DateTime.local().setZone(TIMEZONE)
    }

    start = start.startOf('week')

    for (let i = 0; i < this.$props.initialNumReports; i++) {
      const end = start.plus({ days: 7 })

      this.weeks.push({ start, end })

      start = start.minus({ days: 7 })
    }
  },
  methods: {
    loadMore() {
      if (! this.weeks) {
        return
      }

      let start = this.weeks[this.weeks.length -1].start

      for (let i = 0; i < this.$props.incrementalNumReports; i++) {
        start = start.minus({ days: 7 })

        const end = start.plus({ days: 7 })

        this.weeks.push({ start, end })
      }
    }
  }
}
</script>


