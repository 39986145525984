<template>
  <div v-if="steps || runs || isCurrent" class="summary activity-summary">
    <h2>Activity</h2>

    <div class="activity" v-if="steps">
      <SingleNumeric :value="steps" /> steps,
      <SingleNumeric :value="totalDistance" /> km travelled
      <template v-if="runningIncluded">(runs included)</template>
    </div>
    <div class="activity" v-else-if="isCurrent">
      No steps recorded yet...
    </div>

    <div v-if="runs || isCurrent" class="separator" style="clear: both"></div>

    <div class="activity" v-if="runs">
      <SingleNumeric :value="runs" />
        running activit<template v-if="runs > 1">ies</template>
                       <template v-else>y</template>
        for a total of
      <SingleNumeric :value="runDistance" /> km
    </div>
    <!--
    <div class="activity" v-else-if="isCurrent">
      I should go running...<template v-if="daysSinceLastRun !== null">
      it has been <SingleNumeric :value="daysSinceLastRun" /> days since my last
      one!</template>
    </div>
    -->

  </div>
</template>

<style lang="stylus" scoped>
.separator
  margin 10px 0 10px 0
</style>

<script>
import { DateTime } from 'luxon'

import { TIMEZONE, getLastEventByFeed, parseOccurred } from '@/eventlog'

import SingleNumeric from '@/components/blocks/SingleNumeric.vue'

export default {
  name: 'Activity',
  components: {
    SingleNumeric
  },
  inject: ['getEvents', 'isCurrent'],
  data() {
    return {
      steps: 0,
      totalDistance: 0,
      runs: 0,
      runDistance: 0,
      daysSinceLastRun: null,
      runningIncluded: false
    }
  },
  async created() {
    const fitbitRegex = /([0-9]+) steps.*, ([0-9.]+) km/
    const appleHealthRegex = fitbitRegex
    const runkeeperRegex = /([0-9.]+) km running/

    const events = await this.getEvents

    const data = {}

    for (const event of events) {

      if (
        event.feed.short_name !== 'fitbit' &&
        event.feed.short_name !== 'applehealth' &&
        event.feed.short_name !== 'runkeeper'
      ) {
        continue
      }

      const date =
        parseOccurred(event.occurred).toLocaleString(DateTime.DATE_FULL)

      if (! data[date]) {
        data[date] = {}
      }

      if (event.feed.short_name === 'fitbit') {
        const [, stepsDelta, distanceDelta] = fitbitRegex.exec(event.text)

        data[date].fitbit = {
          steps: parseInt(stepsDelta),
          distance: parseFloat(distanceDelta)
        }

      } else if (event.feed.short_name === 'applehealth') {
        const [, stepsDelta, distanceDelta] = appleHealthRegex.exec(event.text)

        data[date].applehealth = {
          steps: parseInt(stepsDelta),
          distance: parseFloat(distanceDelta)
        }

      } else if (event.feed.short_name === 'runkeeper') {
        this.runs += 1
        const [, distanceDelta] = runkeeperRegex.exec(event.text)

        this.runDistance += parseFloat(distanceDelta)

        if (data[date].runkeeper) {
          data[date].runkeeper.distance += parseFloat(distanceDelta)
        } else {
          data[date].runkeeper = {
            distance: parseFloat(distanceDelta)
          }
        }

      }
    }

    this.finalizeTotals(data)

    if (this.isCurrent && this.runs < 1) {
      const lastRun = await getLastEventByFeed('runkeeper')

      const lastRunOccurred = parseOccurred(lastRun.occurred)

      const now = DateTime.local().setZone(TIMEZONE)

      const durationSince = now.diff(lastRunOccurred, 'days')

      this.daysSinceLastRun = parseInt(durationSince.days)
    }
  },
  methods: {
    finalizeTotals(data) {
      for (const date in data) {

        if (data[date].fitbit && data[date].fitbit.steps > 0) {
          this.totalDistance += data[date].fitbit.distance
          this.steps += data[date].fitbit.steps
        } else if (data[date].applehealth) {
          this.totalDistance += data[date].applehealth.distance
          this.steps += data[date].applehealth.steps

          if (data[date].runkeeper) {
            // This would mean the step / total distance values include data
            // from the run
            this.runningIncluded = true
          }
        }

      }
    }
  }
}
</script>