<template>
  <span class="single-duration">
    <span class="block-number">
      <template v-if="duration.days">
        {{ duration.days }}<span class="block-duration">d </span>
      </template>
      <template v-if="duration.hours">
        {{ duration.hours }}<span class="block-duration">h </span>
      </template>
      <template v-if="duration.minutes">
        {{ duration.minutes }}<span class="block-duration">m </span>
      </template>
      <template v-if="duration.seconds">
        {{ duration.seconds }}<span class="block-duration">s</span>
      </template>
    </span>
  </span>
</template>

<style lang="stylus" scoped>
.single-numeric
  display inline
</style>

<script>
export default {
  name: 'SingleDuration',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    duration () {
      const res = {}
      let total = parseInt(this.value)

      if (total > 3600 * 24) {
        res.days = parseInt(total / (3600 * 24))
        total %= (3600 * 24)
      }

      if (total > 3600) {
        res.hours = parseInt(total / 3600)
        total %= 3600
      }

      if (total > 59) {
        res.minutes = parseInt(total / 60)
        total %= 60
      }

      if (total > 0) {
        res.seconds = total
      }

      return res
    }
  }
}
</script>

