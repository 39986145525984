<template>
  <div v-if="blurbs.length > 0" class="summary writing-summary">
    <h2>Thoughts, Musings and Re-tweets</h2>

    <ol class="writing-list">
      <li v-for="(blurb, index) in blurbs" :key="index" class="blurb">
        <!-- eslint-disable vue/no-v-html -->
        <div class="pull-left blurb-text"
             v-html="blurb.html">
        </div>
        <!-- eslint-enable vue/no-v-html -->
        <div class="pull-right link-time">{{ dateformat(blurb.time) }}</div>
        <div style="clear:both;"></div>
      </li>
    </ol>
  </div>
</template>

<style lang="stylus" scoped>
.writing-list
  list-style none
  padding 0px
  margin 0px

.pull-left
  float left

.pull-right
  float right

.blurb
  display block
  padding-top 2px
  padding-bottom 2px
  border-bottom 1px solid var(--border)

.blurb-text
  width 80%

.blurb-time
  color var(--time)
</style>

<script>
import 'linkify-plugin-mention'
import linkifyStr from 'linkify-string'

import { parseOccurred } from '@/eventlog'

export default {
  name: 'Writing',
  inject: ['getEvents'],
  components: {},
  data() {
    return {
      blurbs: []
    }
  },
  async created() {
    const events = await this.getEvents

    for (const event of events) {
      if (event.feed.short_name === 'twitter') {
        this.blurbs.push({
          html: linkifyStr(
            event.text,
            {
              target: { url: '_self' },
              formatHref: {
                mention(href) { return 'https://twitter.com' + href }
              }
            }
          ),
          time: event.occurred
        })
      }
    }
  },
  methods: {
    dateformat(value) {
      return parseOccurred(value).toFormat('ff')
    }
  }
}
</script>