import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VuePhotoSwipePlugin from './plugins/photoswipe'
import { createVfm } from 'vue-final-modal'
import { VueMasonryPlugin } from 'vue-masonry'

const app = createApp(App)

app.use(router)
app.use(VueMasonryPlugin)
app.use(VuePhotoSwipePlugin)
app.use(createVfm())

app.mount('#app')
