<template>
  <div v-if="links.length > 0" class="summary links-summary">
    <h2>Links</h2>

    <ol class="link-list">
      <li v-for="(link, index) in links" :key="index" class="link">
        <div class="pull-left link-title">
          <a :href="link.url">{{ link.title }}</a>
          <a v-if="link.archived" :href="link.archived" class="archived">#</a>
        </div>
        <div class="pull-right link-time">{{ dateformat(link.time) }}</div>
        <div style="clear:both;"></div>
      </li>
    </ol>
  </div>
</template>

<style lang="stylus" scoped>
.link-list
  list-style none
  padding 0px
  margin 0px

.pull-left
  float left

.pull-right
  float right

.link
  display block
  padding-top 2px
  padding-bottom 2px
  border-bottom 1px solid var(--border)

.archived
  padding-left 5px
  color var(--archived)

.link-title
  width 80%

.link-time
  color var(--time)
</style>

<script>
import { parseOccurred } from '@/eventlog'

export default {
  name: 'Links',
  inject: ['getEvents'],
  components: {},
  data() {
    return {
      links: []
    }
  },
  async created() {
    const events = await this.getEvents

    for (const event of events) {
      if (
        event.feed.short_name === 'reddit' ||
        event.feed.short_name === 'delicious' ||
        event.feed.short_name === 'digg'
      ) {

        const link = {
          url: event.link,
          title: event.title,
          time: event.occurred
        }

        if (event.archived) {
          link.archived = event.archived.path
        }
        this.links.push(link)
      }
    }
  },
  methods: {
    dateformat(value) {
      return parseOccurred(value).toFormat('ff')
    }
  }
}
</script>