<template>
  <div v-if="inspirations.length > 0" class="summary inspiration-summary">
    <h2>Inspiration</h2>

    <ol class="inspiration-list">
      <li v-for="(inspiration, index) in inspirations" :key="index"
          class="inspiration">
        <div class="pull-left inspiration-thumbnail">
          <img :src="inspiration.thumbnail"
               @click="$photoswipe.open(index, images)"
               @load="$redrawVueMasonry()">
        </div>
        <div class="pull-right">
          <a class="inspiration-title"
             :href="inspiration.url">
            {{ inspiration.title }}
          </a> by
          <a v-if="inspiration.author"
             class="inspiration-author"
             :href="inspiration.author.url">{{ inspiration.author.name }}
          </a>
        </div>
        <div style="clear:both;"></div>
      </li>
    </ol>
  </div>
</template>

<style lang="stylus" scoped>
.inspiration-list
  list-style none
  padding 0px
  margin 0px

img:hover
  cursor pointer

.pull-left
  float left

.pull-right
  float right

img
  width 50%

.inspiration
  display block
  padding-top 2px
  padding-bottom 2px
  border-bottom 1px solid var(--border)

.inspiration-title
  width 80%
</style>

<script>
export default {
  name: 'Inspirations',
  inject: ['getEvents'],
  components: {},
  data() {
    return {
      inspirations: [],
      images: []
    }
  },
  async created() {
    const events = await this.getEvents

    for (const event of events) {
      if (
        event.feed.short_name === 'dribbble' ||
        event.feed.short_name === 'behance'
      ) {

        let author = null

        if (event.feed.short_name === 'dribbble') {
          if (event.raw.player) {
            author = {
              url: event.raw.player.url,
              name: event.raw.player.name
            }
          } else {
            author = {
              url: event.raw.shot.user.html_url,
              name: event.raw.shot.user.name
            }
          }
        } else if (event.feed.short_name === 'behance') {
          const firstAuthor = event.raw.project.owners[0]

          author = {
            url: firstAuthor.url,
            name: firstAuthor.display_name
          }
        }

        this.inspirations.push({
          url: event.link,
          title: event.title,
          thumbnail: event.original.path,
          author
        })

        this.images.push({
          src: event.original.path,
          w: event.original.size.width,
          h: event.original.size.height
        })
      }
    }
  }
}
</script>