<template>
  <div class="home">
    <Stream :initial-num-reports="3" :end="streamEnd" />
  </div>
</template>

<script>
// @ is an alias to /src
import Stream from '@/components/Stream.vue'

export default {
  name: 'Home',
  components: {
    Stream
  },
  props: {
    streamEnd: {
      type: Object,
      default: undefined
    }
  }
}
</script>
