import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'

function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)

  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
}

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: (route) => {
        if (route.query.latest === undefined) {
          return {}
        }

        let [year, week] = route.query.latest.split('.')

        year = parseInt(year)
        week = parseInt(week)

        if (
          ! Number.isInteger(year) ||
          ! Number.isInteger(week) ||
          year <= 0 ||
          week <= 0
        ) {
          console.warn(
            `Received invalid query param latest=${route.query.latest}`
          )

          return {}
        }

        return { streamEnd: { year, week } }
      }
    },
    {
      path: '/random',
      name: 'random',
      component: Home,
      props: () => {
        const year = getRandomIntInclusive(2007, 2023)
        const week = getRandomIntInclusive(1, 52)

        return { streamEnd: { year, week } }
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component() {
        return import(/* webpackChunkName: "about" */ './views/About.vue')
      }
    },
    {
      path: '/yearlies',
      name: 'yearlies',
      // route level code-splitting
      // this generates a separate chunk (yearlies.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component() {
        return import(/* webpackChunkName: "yearlies" */ './views/Yearlies.vue')
      },
      props: {
        years: [
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
          '2023'
        ]
      }
    },
    {
      path: '/reports'
    },
    {
      path: '/api'
    },
    {
      path: '/:pathMatch(.*)*',
      component() {
        return import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
      }
    }
  ]
})
