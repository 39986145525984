<template>
  <div class="summary listening-summary">
    <h2>Listening</h2>
    <template v-if="count">
      <img v-for="(url, index) in albumArt.urls" :key="index"
           :src="url"
           :style="{width: albumArt.fraction + '%'}"
           @load="$redrawVueMasonry()">
      <div style="clear: both"></div>

      <SingleNumeric :value="count" /> songs by
      <SingleNumeric :value="artists" />
      artist<template v-if="artists > 1">s</template> in
      <a href="#" @click.prevent.stop="showDetails()">
        <SingleNumeric :value="sessions.length" />
        session<template v-if="sessions.length > 1">s</template>
      </a>
      <template v-if="elapsed">, for a total listening time of
        <SingleDuration :value="elapsed" />
      </template>

    </template>
    <div v-else-if="isCurrent">
      No tunes yet...
    </div>
    <div v-else>
      No listening recorded this week.
    </div>
  </div>
</template>

<style lang="stylus" scoped>
img
  margin: 0px
  padding: 0px
  float: left
</style>

<script>
import SingleDuration from '@/components/blocks/SingleDuration.vue'
import SingleNumeric from '@/components/blocks/SingleNumeric.vue'

import ListeningSessions from '@/components/details/ListeningSessions.vue'

import { useModal } from 'vue-final-modal'

export default {
  name: 'Listening',
  inject: ['getEvents', 'isCurrent'],
  components: {
    SingleDuration,
    SingleNumeric
  },
  data() {
    return {
      elapsed: 0,
      sessions: [],
      count: 0,
      artists: 0,
      albumArt: {
        urls: [],
        fraction: 0.000
      }
    }
  },
  async created() {
    const events = await this.getEvents

    const albumArtUrls = new Map()
    const artists = new Set()

    for (const event of events) {

      if (event.feed.short_name !== 'lastfm') {
        continue
      }

      artists.add(event.raw.artist['#text'])

      if (event.thumbnail) {
        albumArtUrls.set(event.raw.album['#text'], event.thumbnail.path)
      }

      this.count += 1
      this.sessions.push(event)

      if (event.related) {
        this.count += event.related.length

        for (const related of event.related) {
          artists.add(related.raw.artist['#text'])

          if (related.thumbnail) {
            albumArtUrls.set(related.raw.album['#text'], related.thumbnail.path)
          }
        }
      }

      this.elapsed += this.getListeningDuration(event)
    }

    this.artists = artists.size
    this.albumArt.urls = [...albumArtUrls.values()]

    this.determineAlbumArtLayout(this.albumArt)
  },
  methods: {
    getListeningDuration(e) {
      if (! e.related) {
        return 0;
      }

      const last = e.related[e.related.length - 1]

      return parseInt(last.raw.date.uts) - parseInt(e.raw.date.uts)
    },
    determineAlbumArtLayout(details) {
      if (details.urls.length >= 16) {
        details.urls = details.urls.slice(0, 16),
        details.fraction = 25.000
      } else if (details.urls.length >= 9) {
        details.urls = details.urls.slice(0, 9),
        details.fraction = 33.333
      } else if (details.urls.length >= 4) {
        details.urls = details.urls.slice(0, 4),
        details.fraction = 50.000
      } else if (details.urls.length) {
        details.urls = details.urls.slice(0, 1),
        details.fraction = 50.000
      }
    },
    showDetails() {
      const { open, close } = useModal({
        component: ListeningSessions,
        attrs: {
          sessions: this.sessions,
          onClose() {
            close()
          }
        }
      })

      open()
    }
  }
}
</script>