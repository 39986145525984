<template>
  <div v-if="edited.count || spontaneous.count"
       class="summary photography-summary">
    <h2>Photos</h2>

    <div v-if="edited.count" class="edited">
      <img v-for="(url, index) in edited.thumbnails.urls" :key="index"
           :src="url"
           :style="{width: edited.thumbnails.fraction + '%'}"
           @load="$redrawVueMasonry()">
      <div style="clear: both"></div>

      <a href="#"
         @click.prevent.stop="$photoswipe.open(0, edited.items)">
        <SingleNumeric :value="edited.count" /> photos
      </a>
      in <SingleNumeric :value="edited.sessions" />
      edited session<template v-if="edited.sessions > 1">s</template>
      with an average time from taken to edit of
      <SingleDuration :value="edited.avgEditingDelay" />
    </div>

    <div v-if="spontaneous.count" class="spontaneous"
         :class="{ spaced: edited.count }">
      <img v-for="(photo, index) in spontaneous.photos" :key="index"
           :src="photo.thumbnail"
           :style="{width: spontaneous.thumbnailFraction + '%'}"
           @click="$photoswipe.open(index, spontaneous.items)"
           class="spontaneous-thumbnail"
           @load="$redrawVueMasonry()">
      <div style="clear: both"></div>

      <SingleNumeric :value="spontaneous.count" />
      spontaneous photo<template v-if="spontaneous.count > 1">s</template>
    </div>
    <!--
    <div v-else-if="spontaneous.daysSinceLast !== null"
         class="spontaneous" :class="{ spaced: edited.count }">
      It has been <SingleNumeric :value="spontaneous.daysSinceLast" />
      days since my last sponteneous photo!
    </div>
    -->

    <div style="clear: both"></div>
  </div>
</template>

<style lang="stylus" scoped>
.photography-summary
  width fit-content

img
  margin 0px
  padding 0px
  float left

.edited
.spontaneous
  float left
  width 400px

img.spontaneous-thumbnail:hover
  cursor pointer

.spaced
  margin-left 20px

@media (max-width: 950px)
  .spaced
    margin-left 0
    margin-top 20px

  .photography-summary
    width 400px

@media (max-width: 720px)
  .edited
  .spontaneous
    width 100%

  .photography-summary
    width 100%
</style>

<script>
import { DateTime } from 'luxon'

import { parseOccurred, TIMEZONE, getLastEventByFeed } from '@/eventlog'

import SingleDuration from '@/components/blocks/SingleDuration.vue'
import SingleNumeric from '@/components/blocks/SingleNumeric.vue'

export default {
  name: 'Photography',
  inject: ['getEvents', 'isCurrent'],
  components: {
    SingleNumeric,
    SingleDuration
  },
  data() {
    return {
      edited: {
        sessions: 0,
        count: 0,
        thumbnails: {
          urls: [],
          fraction: 0.000
        },
        avgEditingDelay: 0.000,
        items: []
      },
      spontaneous: {
        count: 0,
        thumbnailFraction: 0,
        photos: [],
        items: [],
        daysSinceLast: null
      }
    }
  },
  async created() {
    const events = await this.getEvents

    for (const event of events) {

      if (event.feed.short_name === 'flickr') {

        this.edited.avgEditingDelay += this.editedDelay(event).seconds

        this.edited.items.push({
          src: event.raw.url_l,
          w: event.raw.width_l,
          h: event.raw.height_l,
          title: event.title
        })

        if (event.thumbnail) {
          this.edited.thumbnails.urls.push(event.thumbnail.path)
        }

        this.edited.count += 1
        this.edited.sessions += 1

        if (event.related) {
          this.edited.count += event.related.length

          for (const related of event.related) {
            if (related.thumbnail) {
              this.edited.thumbnails.urls.push(related.thumbnail.path)
            }

            this.edited.avgEditingDelay += this.editedDelay(related).seconds

            this.edited.items.push({
              src: related.raw.url_l,
              w: related.raw.width_l,
              h: related.raw.height_l,
              title: related.title
            })
          }
        }
      } else if (event.feed.short_name === 'instagram') {
        if (event.thumbnail) {
          this.spontaneous.photos.push({
            thumbnail: event.thumbnail.path,
            original: event.original.path
          })
        }

        this.spontaneous.items.push({
          src: event.original.path,
          w: event.original.size.width,
          h: event.original.size.height
        })

        this.spontaneous.count += 1
      }
    }

    if (this.edited.count > 0) {
      this.edited.avgEditingDelay /= this.edited.count
    }

    this.determineThumbnailLayout(this.edited.thumbnails)
    this.determineGalleryThumbnailLayout(this.spontaneous)

    if (this.isCurrent && this.spontaneous.count < 1) {
      const lastSpontaneous = await getLastEventByFeed('instagram')

      const lastSpontaneousOccurred = parseOccurred(lastSpontaneous.occurred)

      const now = DateTime.local().setZone(TIMEZONE)

      const durationSince = now.diff(lastSpontaneousOccurred, 'days')

      this.spontaneous.daysSinceLast = parseInt(durationSince.days)
    }
  },
  methods: {
    determineThumbnailLayout(details) {
      if (details.urls.length >= 16) {
        details.urls = details.urls.slice(0, 16),
        details.fraction = 25.000
      } else if (details.urls.length >= 9) {
        details.urls = details.urls.slice(0, 9),
        details.fraction = 33.333
      } else if (details.urls.length >= 4) {
        details.urls = details.urls.slice(0, 4),
        details.fraction = 50.000
      } else if (details.urls.length) {
        details.urls = details.urls.slice(0, 1),
        details.fraction = 50.000
      }
    },
    determineGalleryThumbnailLayout(details) {
      if (details.photos.length == 0) {
        return
      } else if (details.photos.length % 4 == 0) {
        details.thumbnailFraction = 25.000
      } else if (details.photos.length % 3 == 0) {
        details.thumbnailFraction = 33.333
      } else if (details.photos.length % 2 == 0) {
        details.thumbnailFraction = 50.000
      } else if (details.photos.length > 4) {
        details.thumbnailFraction = 25.000
      } else {
        // should imply there's exactly 1 photo
        details.thumbnailFraction = 100.000
        details.photos[0].thumbnail = details.photos[0].original
      }
    },
    editedDelay(event) {
      const taken = DateTime.fromFormat(event.raw.datetaken, 'y-LL-dd HH:mm:ss')
      const edited = parseOccurred(event.occurred)

      return edited.diff(taken, ['seconds'])

    },
  }
}
</script>