<template>
  <div class="weekly-report">
    <h1 v-if="isCurrent">This Week</h1>
    <h1 v-else>{{ displayStart }} to {{ displayEnd }}</h1>

    <div v-masonry v-if="events !== null && events.length > 0"
         item-selector=".weekly-report-item">
      <Listening v-masonry-tile class="weekly-report-item" />
      <Photography v-masonry-tile class="weekly-report-item" />
      <Activity v-masonry-tile class="weekly-report-item" />
      <Links v-masonry-tile class="weekly-report-item" />
      <Places v-masonry-tile class="weekly-report-item" />
      <Inspiration v-masonry-tile class="weekly-report-item" />
      <Writing v-masonry-tile class="weekly-report-item" />
      <Reading v-masonry-tile class="weekly-report-item" />
    </div>
    <div v-else class="summary empty-report">
      <p>Nothing to report...</p>
    </div>

  </div>
</template>

<style lang="stylus">
.empty-report
.weekly-report
  margin 0
  text-align left

.empty-report
  padding-top 20px
  padding-bottom 20px

.summary
  margin 10px
  width 400px

h1
  padding-bottom 0
  margin-bottom -10px
  border-bottom 1px solid var(--border)

.block-number
  font-weight bold

@media (max-width: 720px)
  .summary
    margin 0
    margin-bottom 10px
    width 100%
</style>

<script>
import { DateTime } from 'luxon'

import { TIMEZONE, getEventsByTimeRange } from '@/eventlog'

import Listening from './summaries/Listening.vue'
import Activity from './summaries/Activity.vue'
import Links from './summaries/Links.vue'
import Photography from './summaries/Photography.vue'
import Places from './summaries/Places.vue'
import Inspiration from './summaries/Inspiration.vue'
import Writing from './summaries/Writing.vue'
import Reading from './summaries/Reading.vue'

const DATE_FORMAT = 'EEE, MMM d y'

export default {
  name: 'WeeklyReport',
  components: {
    Listening,
    Activity,
    Links,
    Photography,
    Places,
    Inspiration,
    Writing,
    Reading
  },
  data() {
    return {
      getEvents: getEventsByTimeRange(this.start, this.end),
      events: null,
      now: DateTime.local().setZone(TIMEZONE)
    }
  },
  props: {
    start: {
      type: DateTime,
      required: true
    },
    end: {
      type: DateTime,
      required: true
    }
  },
  async created() {
    try {
      this.events = await this.getEvents

      console.log('events loaded.')
    } catch (err) {
      console.error('Unable to load events: ', err)
    }
  },
  async mounted() {
    await this.getEvents

    this.$redrawVueMasonry()
  },
  computed: {
    displayStart() {
      return this.start.toFormat(DATE_FORMAT)
    },
    displayEnd() {
      return this.end.minus({ milliseconds: 1 }).toFormat(DATE_FORMAT)
    },
    isCurrent() {
      return (this.now < this.end)
    }
  },
  provide() {
    return {
      getEvents: this.getEvents,
      isCurrent: this.isCurrent
    }
  }
}
</script>