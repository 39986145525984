<template>
  <span class="single-numeric">
    <span class="block-number">
      {{ commaify(value) }}
      <span v-if="units" class="block-units">{{ units }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'SingleNumeric',
  props: {
    value: {
      type: Number,
      required: true
    },
    units: {
      type: String,
      default: undefined
    }
  },
  methods: {
    commaify(input) {
      let res = ''
      let remaining = input

      while (remaining > 1000) {
          const piece = remaining % 1000
          remaining = (remaining - piece) / 1000

          let padding = ''

          if (piece < 100) {
              padding = '0'
          }

          if (piece < 10) {
              padding += '0'
          }

          res = ',' + padding + piece + res
      }

      res = remaining + res

      const indexOf = res.indexOf('.')

      //
      // Cap decimal places at 2
      //
      if (indexOf !== -1) {
          res = res.substring(0, indexOf + 3)
      }

      return res
    }
  }
}
</script>

