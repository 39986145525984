<script setup>
import { VueFinalModal } from 'vue-final-modal'
</script>

<template>
  <VueFinalModal
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
    :overlay-style="overlayStyle"
  >
  <div class="details listening-sessions" @click.self="$emit('close')">
    <div class="listening-sessions-inner">
      <div class="close" @click="$emit('close')">
        ✕
      </div>
      <div v-for="(session, i) in sessions" :key="i"
          class="session" :class="{ 'spaced': i != 0}">
        <span class="title">
          {{ songCount(session) }}
          song<template v-if="songCount(session) > 1">s</template>
          on {{ dateonly(session.occurred) }}
        </span>

        <ol class="song-list">
          <li v-for="(song, j) in songs(session)" :key="j" class="song">
            <div class="pull-left song-title">
              <a :href="song.link">{{ song.text }}</a>
            </div>
            <div class="pull-right song-time">{{ timeonly(song.occurred) }}</div>
            <div style="clear:both;"></div>
          </li>
        </ol>
      </div>
    </div>
  </div>
  </VueFinalModal>
</template>

<style lang="stylus" scoped>
.modal-content
  inset 0
  position absolute

.listening-sessions
  position absolute
  inset 0
  height 100%
  overflow auto

.listening-sessions-inner
  padding 50px
  color var(--text)
  background var(--background)
  width 620px
  box-shadow 0 20px 60px -2px rgba(27, 33, 58, 0.4)
  margin-left auto
  margin-right auto
  margin-top 5vh
  border-radius 0.3rem

.close
  position relative
  float right
  top -45px
  right -40px
  cursor pointer
  font-size 20px
  color var(--link)
  -webkit-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none

.spaced
  margin-top: 30px

.title
  padding 5px 0px 10px 0px
  display: block

.song-list
  list-style none
  padding 0px
  margin 0px

.pull-left
  float left

.pull-right
  float right

.song
  display block
  padding-top 2px
  padding-bottom 2px
  border-bottom 1px solid var(--border)

a
a:hover
a:visited
  font-weight normal
  text-decoration none

.song-title
  width 80%

.song-time
  color var(--time)

@media (max-width: 720px)
  .listening-sessions-inner
    padding 50px 5px 50px 5px
    width calc(100vw - 10px)
    margin-top 2vh

  .close
    top -45px
    right 5px

</style>

<script>
export default {
  name: 'ListeningSessions',
  props: {
    sessions: {
      type: Array,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    overlayStyle() {
      const narrow = window.matchMedia("(max-width: 720px)")

      if (narrow.matches) {
        return "background-color:rgba(0, 0, 0, 0);"
      } else {
        return "background-color:rgba(0, 0, 0, 0.8);"
      }
    }
  },
  methods: {
    songCount(session) {
      let count = 1

      if (session.related) {
        count += session.related.length
      }

      return count
    },
    songs(session) {
      let entries = [session]

      if (session.related) {
        entries = entries.concat(session.related)
      }

      return entries
    },
    dateonly(value) {
      return value.match(/\d+-\d+-\d+/)[0]
    },
    timeonly(value) {
      return value.match(/\d\d:\d\d:\d\d/)[0]
    }
  },
};
</script>