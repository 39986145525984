import { default as http } from 'axios'
import { DateTime } from 'luxon'

export const TIMEZONE = process.env.VUE_APP_TIMEZONE

const DATETIME_FMT = 'y-MM-dd HH:mm:ss.SSS'
const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH

const instance = http.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

export async function getEventsByTimeRange(start, end) {

  const after = start.minus({ 'milliseconds': 1 }).toFormat(DATETIME_FMT)
  const before = end.toFormat(DATETIME_FMT)

  console.log(`fetching events after=${after}, before=${before}`)

  let events = []
  let url = `${API_BASE_PATH}/events`
  let config = {
    params: {
      embed_related: 'full',
      tz: TIMEZONE,
      after,
      before
    }
  }

  while (url) {
    const response = await instance.get(url, config)

    events = events.concat(response.data.data)

    url = response.data.pagination.next
    config = undefined // url contains params after the first request.
  }

  return events.reverse()
}

export function parseOccurred(value) {
  //
  // Current implementation strips the fractional seconds.
  //
  const noFractional = value.replace(/\.[0-9]+/, '')

  return DateTime.fromFormat(noFractional, 'y-LL-dd HH:mm:ssZZZ')
}

export async function getLastEventByFeed(feed) {
  console.log(`fetching last ${feed} event`)

  const response = await instance.get(`${API_BASE_PATH}/events`, {
    params: {
      feeds: feed,
      tz: TIMEZONE,
      limit: 1,
      embed_related: 'full'
    }
  })

  return response.data.data[0]
}
