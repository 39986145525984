<template>
  <div v-if="started || finished.length > 0 || currentlyReading"
       class="summary reading-summary">
    <h2>Reading</h2>
    <div v-if="currentlyReading">
      <h3>Currently</h3>
      <img class="pull-left book-cover" :src="currentlyReading.original.path">
      <div class="pull-left book-title">
        <a :href="currentlyReading.link">{{ currentlyReading.title }}</a>
        by {{ currentlyReading.raw.author_name }}
      </div>
      <div style="clear: both"></div>
    </div>
    <div v-else-if="started">
      <h3>Started</h3>
      <img class="pull-left book-cover" :src="started.original.path">
      <div class="pull-left book-title">
        <a :href="started.link">{{ started.title }}</a>
        by {{ started.raw.author_name }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div v-if="finished.length > 1">
      <h3>Finished</h3>
      <img class="pull-left book-cover"
           v-for="(details, index) in finished"
           :key="index"
           :src="details.read.original.path"
           @load="$redrawVueMasonry()">
      <div class="pull-left book-titles">
        <span v-for="(details, index) in finished" :key="index">
          <a :href="details.read.link">
            {{ details.read.title }}
          </a>
          by <span>{{ details.read.raw.author_name }}</span>
          <template v-if="index !== finished.length - 1">, </template>
        </span>
      </div>
      <div style="clear: both"></div>
    </div>
    <div v-if="finished.length == 1">
      <h3>Finished</h3>
      <img class="pull-left book-cover" :src="finished[0].read.original.path">
      <div class="pull-left book-title">
        <a :href="finished[0].read.link">{{ finished[0].read.title }}</a>
        by {{ finished[0].read.raw.author_name }}
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.pull-left
  float left

.book-title
  display block
  padding-left 10px
  width: 50%

.book-titles
  display block
  padding-top 10px
  width: 100%

.book-cover
  display block
  width 25%
</style>

<script>
import { getLastEventByFeed } from '@/eventlog'

export default {
  name: 'Reading',
  inject: ['getEvents', 'isCurrent'],
  components: {},
  data() {
    return {
      started: null,
      finished: [],
      currentlyReading: null
    }
  },
  async created() {
    const events = await this.getEvents

    const books = {}

    for (const event of events) {
      if (event.feed.short_name === 'goodreads') {

        if (! books[event.title]) {
            books[event.title] = {
                state: event.text,
                details: {
                    [event.text]: event
                }
            }
        } else {
            books[event.title].state = event.text
            books[event.title].details[event.text] = event
        }
      }
    }

    for (const title in books) {
        if (books[title].state == 'currently-reading') {
            const event = books[title].details['currently-reading']

            if (this.isCurrent) {
                this.currentlyReading = event
            } else {
                this.started = event
            }
        } else {
            this.finished.push(books[title].details)
        }
    }

    if (this.isCurrent && ! this.currentlyReading) {
        const event = await getLastEventByFeed('goodreads')

        if (event.text == 'currently-reading') {
            this.currentlyReading = event
        }
    }
  }
}
</script>