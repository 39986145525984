import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

let $lightbox

export default {
  install(app) {
    if (!$lightbox) {
      const el = document.createElement('div')

      $lightbox = new PhotoSwipeLightbox({
        gallery: el,
        pswpModule: () => import('photoswipe'),
      });

      document.body.appendChild(el)

      $lightbox.init();
    }

    app.$photoswipe = {
      open(index, items) {
        $lightbox.loadAndOpen(index, items)
      },
    }

    app.mixin({
      created() {
        this.$photoswipe = app.$photoswipe
      }
    })
  }
}